import React, { useRef } from 'react'
import classnames from 'classnames'
import { Link } from '@deal/router'
import {
  Button,
  Carousel,
  CarouselController,
  CarouselRenderPaginationPage,
  Image
} from '@deal/components'
import { useBreakpoint } from '#src/app/hooks/useBreakpoint'
import PageContent from '#src/app/components/PageContent'
import sections from './data'
import styles from './styles.css'

interface PaginationProps {
  pages: CarouselRenderPaginationPage[]
}

interface Props {
  pathLoading?: boolean
  onCtaClick: () => void
}

const Pagination: React.FC<React.PropsWithChildren<PaginationProps>> = ({ pages }) => (
  <div className={styles.dotContainer}>
    <ul className={styles.dots}>
      {pages.map((page, index) => {
        return (
          <li key={index} className={styles.dot}>
            <button
              className={classnames(styles.dotBtn, {
                [styles.active]: page.active
              })}
              onClick={page.onClick}
              aria-label={`Scroll to page ${index + 1}`}
            />
          </li>
        )
      })}
    </ul>
  </div>
)

const ShoppingWithExpert: React.FC<Props> = ({ pathLoading, onCtaClick }) => {
  const sliderRef = useRef<CarouselController>(null)
  const isLargerThanSm = useBreakpoint('sm')
  const isLargerThanMd = useBreakpoint('md')

  return (
    <>
      <a id="expert" />
      <PageContent className={styles.container} gridWrapperClassName={styles.gridWrapper}>
        <div className={styles.header}>Your most helpful new friend, a Real Expert.</div>
        <div className={styles.carouselContainer}>
          <Carousel
            ref={sliderRef}
            slidesToScroll={1}
            gutterSize={24}
            edgePadding={0}
            renderPagination={pages => (isLargerThanMd ? null : <Pagination pages={pages} />)}
            className={styles.carousel}
            xsProps={{
              slidesToShow: 1
            }}
            smProps={{
              slidesToShow: 2,
              arrows: false,
              infinite: false
            }}
            mdProps={{
              slidesToShow: 3
            }}
          >
            {sections.map(section => {
              return (
                <div className={styles.carouselCard} key={section.label}>
                  <div className={styles.imgWrapper}>
                    <Image
                      size="100vw"
                      sizeSm="50vw"
                      sizeMd="33vw"
                      src={section.img}
                      className={styles.image}
                      htmlAttributes={{ alt: section.label }}
                    />
                  </div>
                  <div className={styles.cardLabel}>{section.label}</div>
                  <div className={styles.cardContent}>{section.content}</div>
                </div>
              )
            })}
          </Carousel>
          {!isLargerThanSm && (
            <Button
              disabled={pathLoading}
              variant="primary-gradient"
              className={styles.ctaSmallScreenBtn}
              onClick={onCtaClick}
            >
              Shop with a Real Expert
            </Button>
          )}
        </div>
        <div className={styles.actionsWrapper}>
          {isLargerThanSm && (
            <Button
              disabled={pathLoading}
              variant="primary-gradient"
              className={styles.ctaBtn}
              size="large"
              onClick={onCtaClick}
            >
              Shop with a Real Expert
            </Button>
          )}
          <div className={styles.applyWrapper}>
            <div className={styles.applyText}>Driven by passion? Become a Real Expert. </div>
            <Link to="/apply" className={styles.applyLink}>
              Apply now
            </Link>
          </div>
        </div>
      </PageContent>
    </>
  )
}

export default ShoppingWithExpert

import React, { useState } from 'react'
import useFeedHomePageEligibilityCheck from '#src/app/services/for-you/hooks/useFeedHomePageEligibilityCheck'
import ShoppingWithExpert from '#src/app/routes/home/page/components/ShoppingWithExpert'
import { PageComponentProps } from '#src/app/components/Page'
import HotjarSnippet from '#src/app/components/HotjarSnippet'
import Footer from '#src/app/components/Footer'
import CategoryPathCtaModal from '#src/app/components/CategoryPathCtaModal'
import { HomeRouteQuery } from '../HomeRoute.generated'
import { HeroWithRequestPost } from '../../shop/page/components/Hero/HeroWithRequestPost'
import { ScoutFormHero } from './components/ScoutFormHero'
import { ScoutBanner } from './components/ScoutBanner'
import SchemaData from './components/SchemaData'
import { PopularArticles } from './components/PopularArticles'
import Hero from './components/Hero'
import ExploreContent from './components/ExploreContent'
import ExploreCategories from './components/ExploreCategories'
import ExpertCommunity from './components/ExpertCommunity'
import CurationsHero from './components/CurationsHero'
import Brands from './components/Brands'
import styles from './styles.css'

export interface HomePageProps extends PageComponentProps<HomeRouteQuery> {
  isScoutHomePage?: boolean
}

const Home: React.FC<HomePageProps> = ({ isScoutHomePage, data }) => {
  const [isPathModalOpen, setIsPathModalOpen] = useState(false)

  const eligibleForFeedHomePage = useFeedHomePageEligibilityCheck()

  if (isScoutHomePage) {
    const sellables = data.searchSellables?.edges.map(edge => edge.node) || []
    return (
      <>
        <SchemaData />
        <ScoutFormHero sellables={sellables} />
        <Brands isInverted />
        <ExploreCategories
          layout="scout"
          navigationGroups={data.formattedCategoryNavigationsByGroup}
        />
        <PopularArticles />
        <ScoutBanner />
        <Footer />
        <HotjarSnippet />
      </>
    )
  }

  const validUserJournies = data.me?.user.userJourneyStages.filter(journey => {
    if (journey.__typename === 'UserJourneyLeadStage') {
      const userJourneyLeadStage = journey
      if (
        userJourneyLeadStage.type === 'LEAD' &&
        userJourneyLeadStage.leadStageStatus === 'CURATION_READY'
      ) {
        return true
      }
    }
    return false
  })

  const showCurationHero = validUserJournies && validUserJournies.length > 0

  return (
    <>
      <SchemaData />
      {eligibleForFeedHomePage ? (
        <div className={styles.topSpacing}>
          <HeroWithRequestPost />
        </div>
      ) : !showCurationHero ? (
        <Hero onPathModalOpen={() => setIsPathModalOpen(true)} />
      ) : (
        <CurationsHero
          onPathModalOpen={() => setIsPathModalOpen(true)}
          userJourneyStages={validUserJournies}
        />
      )}
      <ExploreCategories navigationGroups={data.formattedCategoryNavigationsByGroup} />
      <div className={styles.shoppingWithExpert}>
        <ShoppingWithExpert onCtaClick={() => setIsPathModalOpen(true)} />
      </div>
      <ExpertCommunity experts={data.expertSearchConnection?.edges.map(edge => edge.node) || []} />
      <ExploreContent />
      <Brands />
      <Footer />
      <HotjarSnippet />
      <CategoryPathCtaModal
        isPathModalOpen={isPathModalOpen}
        onPathModalClose={() => setIsPathModalOpen(false)}
        navigationGroups={data.formattedCategoryNavigationsByGroup}
      />
    </>
  )
}

export { Home }

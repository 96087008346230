import React from 'react'
import PageContent from '#src/app/components/PageContent'
import ResponsiveCarousel from '#src/app/components/Carousel/ResponsiveCarousel'
import { BusinessUserForExpertCommunityFragment } from './BusinessUserForExpertCommunity.generated'
import FeaturedExpertsCard from './FeaturedExpertsCard'
import styles from './styles.css'

interface ExpertCommunityProps {
  experts: BusinessUserForExpertCommunityFragment[]
}

const ExpertCommunity: React.FC<ExpertCommunityProps> = ({ experts }) => {
  const featuredExperts = experts
    .filter(expert => {
      // HACK: We are re-using the same API for featuring "spotlight" experts on the v1 homepage
      //   but we want to show different experts. To distinguish between the set of experts for
      //   the v1 homepage vs v2, we look at whether the expert has an "introduction video". Only
      //   the original set of experts will have these videos, so we exclude them from being
      //   displayed on the v2 homepage. This logic can be removed once we ramp the v2 homepage
      //   and remove the "spotlight" flag from those experts.
      const isFeaturedOnV1Homepage = !!expert.expertProfile?.introductionVideo

      return expert.expertProfile?.profilePhoto && !isFeaturedOnV1Homepage
    })
    // HACK: THe list is sorted alphabetically but we prefer the profiles of those toward the
    //   end of the list, so we reverse it to highlight a better group of experts first.
    .reverse()

  return (
    <div className={styles.container}>
      <PageContent>
        <div className={styles.header}>Meet the Real Experts</div>
      </PageContent>

      <ResponsiveCarousel
        className={styles.carousel}
        slidesToShow={2}
        xsProps={{
          slidesToShow: 1.5,
          arrows: false
        }}
        smProps={{
          slidesToShow: 3
        }}
        mdProps={{
          slidesToShow: 3
        }}
        lgProps={{ arrows: true, slidesToShow: 4 }}
        id={featuredExperts.map(expert => expert.displayName).join()}
      >
        {featuredExperts.map(expert => {
          return (
            <div key={expert.id} className={styles.featuredCard}>
              <FeaturedExpertsCard expert={expert} />
            </div>
          )
        })}
      </ResponsiveCarousel>
    </div>
  )
}

export default ExpertCommunity

// @ts-nocheck
import type * as Types from '../../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { MediaFileFragmentDoc } from '../../../../../fragments/MediaFile.generated';
import { UserJourneyLeadForCurationFragmentDoc } from './Curation/Curation.generated';
export type ExpertForCurationsHeroFragment = { __typename: 'BusinessUser', id: any, vanityId?: string | null, displayName: string, shortName: string, state: Types.BusinessUserState, expertAttributes: { __typename: 'BusinessUserExpertAttributes', placeholderExpert: boolean }, expertProfile?: { __typename: 'ExpertProfile', id: any, headline: string, gender?: Types.ExpertGender | null, profilePhoto?: { __typename: 'MediaFile', id: any, url: string, mimeType: string, blurHash?: string | null, videoStream?: { __typename: 'VideoStreamMediaFileMetadata', hlsUrl: string, thumbnailUrl: string } | null, dimensions?: { __typename: 'DimensionsMediaFileMetadata', width: number, height: number, aspectRatio: number } | null } | null } | null };

export type UserJourneyLeadStageForCurationsHeroFragment = { __typename: 'UserJourneyLeadStage', leadStageStatus: Types.UserJourneyLeadStageStatus, type: Types.UserJourneyStageType, lead: { __typename: 'Lead', id: any, expert: { __typename: 'BusinessUser', id: any, vanityId?: string | null, displayName: string, shortName: string, state: Types.BusinessUserState, expertAttributes: { __typename: 'BusinessUserExpertAttributes', placeholderExpert: boolean }, expertProfile?: { __typename: 'ExpertProfile', id: any, headline: string, gender?: Types.ExpertGender | null, profilePhoto?: { __typename: 'MediaFile', id: any, url: string, mimeType: string, blurHash?: string | null, videoStream?: { __typename: 'VideoStreamMediaFileMetadata', hlsUrl: string, thumbnailUrl: string } | null, dimensions?: { __typename: 'DimensionsMediaFileMetadata', width: number, height: number, aspectRatio: number } | null } | null } | null }, category: { __typename: 'Category', id: any, pluralDisplayName: string }, userNeeds: Array<{ __typename: 'UserNeed', id: any, category: { __typename: 'Category', id: any, slug: string }, curatedItems: { __typename: 'ExpertCuratedItemsForNeeds', count: any, items: Array<{ __typename: 'ExpertCuratedItem', id: any, category: { __typename: 'Category', id: any, slug: string, singularDisplayName: string }, sellable: { __typename: 'Sellable', id: any, images: Array<{ __typename: 'MediaFile', id: any, url: string, mimeType: string, blurHash?: string | null, videoStream?: { __typename: 'VideoStreamMediaFileMetadata', hlsUrl: string, thumbnailUrl: string } | null, dimensions?: { __typename: 'DimensionsMediaFileMetadata', width: number, height: number, aspectRatio: number } | null }> } }> } }> } };

export type UserJourneyStageForCurationsHero_UserJourneyLeadStage_Fragment = { __typename: 'UserJourneyLeadStage', leadStageStatus: Types.UserJourneyLeadStageStatus, type: Types.UserJourneyStageType, lead: { __typename: 'Lead', id: any, expert: { __typename: 'BusinessUser', id: any, vanityId?: string | null, displayName: string, shortName: string, state: Types.BusinessUserState, expertAttributes: { __typename: 'BusinessUserExpertAttributes', placeholderExpert: boolean }, expertProfile?: { __typename: 'ExpertProfile', id: any, headline: string, gender?: Types.ExpertGender | null, profilePhoto?: { __typename: 'MediaFile', id: any, url: string, mimeType: string, blurHash?: string | null, videoStream?: { __typename: 'VideoStreamMediaFileMetadata', hlsUrl: string, thumbnailUrl: string } | null, dimensions?: { __typename: 'DimensionsMediaFileMetadata', width: number, height: number, aspectRatio: number } | null } | null } | null }, category: { __typename: 'Category', id: any, pluralDisplayName: string }, userNeeds: Array<{ __typename: 'UserNeed', id: any, category: { __typename: 'Category', id: any, slug: string }, curatedItems: { __typename: 'ExpertCuratedItemsForNeeds', count: any, items: Array<{ __typename: 'ExpertCuratedItem', id: any, category: { __typename: 'Category', id: any, slug: string, singularDisplayName: string }, sellable: { __typename: 'Sellable', id: any, images: Array<{ __typename: 'MediaFile', id: any, url: string, mimeType: string, blurHash?: string | null, videoStream?: { __typename: 'VideoStreamMediaFileMetadata', hlsUrl: string, thumbnailUrl: string } | null, dimensions?: { __typename: 'DimensionsMediaFileMetadata', width: number, height: number, aspectRatio: number } | null }> } }> } }> } };

export type UserJourneyStageForCurationsHero_UserJourneyOrderStage_Fragment = { __typename: 'UserJourneyOrderStage' };

export type UserJourneyStageForCurationsHeroFragment = UserJourneyStageForCurationsHero_UserJourneyLeadStage_Fragment | UserJourneyStageForCurationsHero_UserJourneyOrderStage_Fragment;

export const ExpertForCurationsHeroFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ExpertForCurationsHero"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUser"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"vanityId"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}},{"kind":"Field","name":{"kind":"Name","value":"shortName"}},{"kind":"Field","name":{"kind":"Name","value":"state"}},{"kind":"Field","name":{"kind":"Name","value":"expertAttributes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"placeholderExpert"}}]}},{"kind":"Field","name":{"kind":"Name","value":"expertProfile"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"headline"}},{"kind":"Field","name":{"kind":"Name","value":"profilePhoto"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MediaFile"}}]}},{"kind":"Field","name":{"kind":"Name","value":"gender"}}]}}]}}]} as unknown as DocumentNode;
export const UserJourneyLeadStageForCurationsHeroFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"UserJourneyLeadStageForCurationsHero"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"UserJourneyLeadStage"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"lead"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"UserJourneyLeadForCuration"}}]}},{"kind":"Field","name":{"kind":"Name","value":"leadStageStatus"}},{"kind":"Field","name":{"kind":"Name","value":"type"}}]}}]} as unknown as DocumentNode;
export const UserJourneyStageForCurationsHeroFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"UserJourneyStageForCurationsHero"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"UserJourneyStage"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"UserJourneyLeadStageForCurationsHero"}}]}}]} as unknown as DocumentNode;
import React from 'react'
import { useHover } from 'react-aria'
import classnames from 'classnames'
import { Image } from '@deal/components'
import { AnnotationWithAsterisk, Button, Heading, Rounded } from '@deal/bluxome'
import { getActiveExpertCountForCategory } from '#src/app/services/categories'
import { useBreakpoint } from '#src/app/hooks/useBreakpoint'
import { FormattedCategoryNavigationRevisionForCategoryNavigationCardFragment } from './FormattedCategoryNavigationRevisionForCategoryNavigationCard.generated'
import ArrowRight from './arrow-right.svg'
import styles from './styles.css'

export interface ScoutCategoryNavigationCardProps {
  categoryNavigationRevision: FormattedCategoryNavigationRevisionForCategoryNavigationCardFragment
  /**
   * Card image normally has a 3/2 aspect ratio, but may be 17/20
   * if rendered in a 4-column layout at lg breakpoint (1040px)
   * @default 3/2
   */
  aspectRatioLg?: '3/2' | '17/20'
}

export const ScoutCategoryNavigationCard: React.FC<ScoutCategoryNavigationCardProps> = ({
  categoryNavigationRevision: { category, displayLabel },
  aspectRatioLg = '3/2'
}) => {
  const { categoryAssetRevision } = category || {}
  const { hoverProps, isHovered } = useHover({})
  const isLargerThanLg = useBreakpoint('lg', 'bluxome')
  const expertCount = category ? getActiveExpertCountForCategory(category) : 0
  return (
    <div {...hoverProps}>
      <Rounded
        bgColor="neutrals-100"
        className={styles.scoutCard}
        style={{ '--aspect-ratio-lg': aspectRatioLg } as React.CSSProperties}
      >
        <div className={styles.scoutCardHeading}>
          <Heading as="h4" size="h4">
            {isHovered ? <u>{displayLabel}</u> : <>{displayLabel}</>}
          </Heading>
          {expertCount > 0 && (
            <AnnotationWithAsterisk size="small" color="neutrals-black">
              {expertCount.toLocaleString()} vetted expert{expertCount > 1 ? 's' : ''}
            </AnnotationWithAsterisk>
          )}
        </div>
        <div className={styles.scoutCardImageContainer}>
          {categoryAssetRevision?.croppedInventoryImage && (
            <Image
              className={classnames(styles.scoutCardImage, { [styles.hovered]: isHovered })}
              src={categoryAssetRevision.croppedInventoryImage.image.url}
              size="66vw"
              sizeSm="33vw"
              sizeMd="20vw"
              htmlAttributes={{
                alt: displayLabel
              }}
              imgixParams={{
                fit: 'crop',
                ar: isLargerThanLg ? aspectRatioLg.replace('/', ':') : '3:2'
              }}
            />
          )}
        </div>
        <div className={styles.scoutCardButton}>
          <Button
            style={isHovered ? 'filled' : 'outline'}
            color={isHovered ? 'neutral-dark' : 'neutral-light'}
            isInverted={isHovered}
            icon={ArrowRight}
            aria-label={`View ${displayLabel}`}
          />
        </div>
      </Rounded>
    </div>
  )
}

import React from 'react'
import { BackgroundImage } from '@deal/components'
import { BaseContentCard, Expert } from '@deal/bluxome'
import PlaceholderImage from '#src/app/assets/images/placeholder.png'
import { ArticleRevisionForVersusArticleCardFragment } from './VersusArticleCard.generated'
import styles from './styles.css'

interface Props {
  article: ArticleRevisionForVersusArticleCardFragment
  author?: Expert
  size: string
  sizeMd?: string
  sizeLg?: string
  sizeXlg?: string
  containerBgColor: 'white' | 'color'
}

export const VersusArticleCard: React.FC<Props> = ({
  containerBgColor,
  author,
  article,
  ...restProps
}) => {
  return (
    <BaseContentCard
      title={article.title}
      summary={article.summaryText}
      author={author}
      imageComponent={
        <div className={styles.versusImagesContainer}>
          <BackgroundImage
            src={article.versusMedia?.firstMedia?.media.url || PlaceholderImage}
            containerClassName={styles.featuredImageAspectRatioContainer}
            className={styles.featuredImage}
            {...restProps}
          />
          <div className={styles.versusText}>VS</div>
          <BackgroundImage
            src={article.versusMedia?.secondMedia?.media.url || PlaceholderImage}
            containerClassName={styles.featuredImageAspectRatioContainer}
            className={styles.featuredImage}
            {...restProps}
          />
        </div>
      }
      containerBgColor={containerBgColor}
      embedContent
    />
  )
}

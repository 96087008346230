import React from 'react'
import { Grid, GridItem } from '@deal/components'
import { Skeleton } from '#src/app/components/SkeletonLoader'
import PageContent from '#src/app/components/PageContent'
import styles from './styles.css'

const HomeRouteLoader: React.FC = () => {
  return (
    <PageContent gridWrapperClassName={styles.gridWrapper}>
      <Skeleton className={styles.title} />
      <Skeleton className={styles.subTitle} />
      <div className={styles.tabTitleWrapper}>
        {new Array(3).fill(null).map((_, index) => {
          return <Skeleton key={`tab-${index}`} width={89} className={styles.tabTitle} />
        })}
      </div>

      <Grid rowGaps className={styles.gridContent}>
        {new Array(10).fill(null).map((_, index) => {
          return (
            <GridItem key={index} smCol={2} mdCol={2}>
              <Skeleton className={styles.card} />
            </GridItem>
          )
        })}
      </Grid>
    </PageContent>
  )
}

export default HomeRouteLoader

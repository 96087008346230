import React from 'react'
import classNames from 'classnames'
import { Image } from '@deal/components'
import { Area, Grid, Heading, Rounded, RoundedImage, Text } from '@deal/bluxome'
import config from '#src/app/config'
import { StartScoutConversationForm } from '#src/app/components/StartScoutConversationForm'
import { SellableForScoutFormHeroFragment } from './ScoutFormHero.generated'
import BackgroundImage from './mountains-background.png'
import styles from './styles.css'

const scoutDepartmentId = config.get('scout.departmentId') || ''

const PRODUCT_CARD_MESSAGES = ["What's my size?", 'Recommend matching gear', 'Do a comparison']

interface ScoutFormHeroProps {
  sellables: SellableForScoutFormHeroFragment[]
}

export const ScoutFormHero: React.FC<ScoutFormHeroProps> = ({ sellables }) => {
  return (
    <div className={styles.hero}>
      <Grid rowGap={40}>
        <Area column="span 6" bleed="full" className={styles.mobileImageArea}>
          <Image
            size="100vw"
            src={BackgroundImage}
            lazyLoad={false}
            imgixParams={{
              fit: 'crop',
              ar: '4:3'
            }}
          />
          <div className={styles.productImages}>
            {sellables.map(sellable => (
              <HeroProduct key={sellable.id} {...sellable} />
            ))}
          </div>
        </Area>
        <Area column="span 6" columnLg="span 5" bleedBelow="md" className={styles.heroContent}>
          <Heading as="h1" size="h1">
            Shop with the expertise of a pro
          </Heading>
          <Text style="base-loose">
            Find the right products with knowledge from former pros, verified specialists and gear
            geeks — all for free.
          </Text>
          <StartScoutConversationForm
            departmentId={scoutDepartmentId}
            submitButton={{ label: 'Get started', color: 'learn-blue' }}
            borderColor={null}
            focusRingColor="neutrals-black"
            placeholderColor="neutrals-600"
            placeholderSuggestions={[
              'Help me find my first snowboard',
              "I'm looking at irons for a tall player",
              'I need bindings to match my skis'
            ]}
          />
        </Area>
        <Area column="span 6" columnLg="span 7" className={styles.desktopImageArea}>
          <RoundedImage
            imageProps={{
              size: '57vw',
              src: BackgroundImage,
              lazyLoad: false
            }}
            aspectRatio="4/3"
            objectFit="contain"
          />
          <div className={styles.productImages}>
            {sellables.map((sellable, i) => {
              return (
                <HeroProduct
                  key={sellable.id}
                  message={PRODUCT_CARD_MESSAGES.at(i)}
                  className={classNames({
                    [styles.one]: i === 0,
                    [styles.two]: i === 1,
                    [styles.three]: i === 2
                  })}
                  {...sellable}
                />
              )
            })}
          </div>
        </Area>
      </Grid>
    </div>
  )
}

interface HeroProductProps extends SellableForScoutFormHeroFragment {
  message?: string
  className?: string
}

const HeroProduct: React.FC<HeroProductProps> = ({
  title,
  parent,
  images,
  message = null,
  className
}) => {
  const src = images.at(0)?.url
  return (
    <div className={classNames(styles.productColumn, className)}>
      {message && (
        <div className={styles.message}>
          <Text as="span" style="small-medium" color="neutrals-black">
            {message}
          </Text>
        </div>
      )}
      <Rounded bgColor="neutrals-white" embedContent>
        <div className={styles.productImage}>
          {!!src && (
            <Image
              src={src}
              size="240px"
              imgixParams={{
                fit: 'fill',
                'fill-color': 'ffffff',
                ar: '4:5'
              }}
            />
          )}
        </div>
        <div className={styles.productTitle}>
          <Text as="div" lines={1} style="extra-small-medium" color="neutrals-white">
            {parent?.title || title}
          </Text>
        </div>
      </Rounded>
    </div>
  )
}

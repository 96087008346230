import React from 'react'
import { Skeleton } from '#src/app/components/SkeletonLoader'
import PageContent from '#src/app/components/PageContent'
import styles from './styles.css'

const HomeRouteLoader: React.FC = () => {
  return (
    <PageContent gridWrapperClassName={styles.gridWrapper} className={styles.wrapper}>
      <Skeleton className={styles.header} />
      <Skeleton className={styles.subHeader} />
      <Skeleton className={styles.ctaWrapper} />
      <Skeleton className={styles.info} />
    </PageContent>
  )
}

export default HomeRouteLoader

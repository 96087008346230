import React, { useEffect, useRef } from 'react'
import classnames from 'classnames'
import {
  Carousel,
  CarouselController,
  CarouselProps,
  Grid,
  GridItem,
  GridItemProps
} from '@deal/components'
import { useBreakpoint } from '#src/app/hooks/useBreakpoint'
import Pagination from '../CarouselPagination'
import styles from './styles.css'

interface ResponsiveCarouselProps extends CarouselProps {
  showPagination?: boolean
  edgeGridItemProps?: GridItemProps
  carouselGridItemProps?: GridItemProps
  gridWrapperClassName?: string
  includeInnerWrapperClass?: boolean
  ignoreMargins?: boolean
  id: string
}

const ResponsiveCarousel: React.FC<React.PropsWithChildren<ResponsiveCarouselProps>> = ({
  children,
  mdProps,
  lgProps,
  gutterSize = 16,
  edgePadding = 16,
  showPagination,
  edgeGridItemProps = {
    col: 2
  },
  carouselGridItemProps = {
    col: 2
  },
  gridWrapperClassName,
  includeInnerWrapperClass = true,
  ignoreMargins = false,
  renderPagination,
  // id is used to track the items inside the carousel, if the items change we want to reset the carousel
  // to the first page.
  id,
  ...rest
}) => {
  const isLargerThanMd = useBreakpoint('md')
  const carouselRef = useRef<CarouselController>(null)
  const firstUpdate = useRef(true)
  // reset the slide position when the children update
  useEffect(() => {
    // only call `goTo` on subsequent updates
    // (this is to prevent a rendering bug where the initial `slidesToShow`
    // prop from SSR doesn't match the client `slidesToShow`, and the `goTo` method
    // miscalculates the translate distance)
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    carouselRef.current?.goTo(0)
  }, [id])

  return (
    <Grid margins={!ignoreMargins && isLargerThanMd} wrapperClassName={gridWrapperClassName}>
      <GridItem {...edgeGridItemProps} />
      <GridItem
        className={classnames({ [styles.wrapper]: includeInnerWrapperClass })}
        {...carouselGridItemProps}
      >
        <Carousel
          ref={carouselRef}
          gutterSize={gutterSize}
          edgePadding={edgePadding}
          mdProps={{ edgePadding: 0, ...mdProps }}
          lgProps={{ gutterSize: 24, ...lgProps }}
          renderPagination={
            renderPagination
              ? renderPagination
              : showPagination
              ? pages => <Pagination pages={pages} />
              : undefined
          }
          {...rest}
        >
          {children}
        </Carousel>
      </GridItem>
      <GridItem {...edgeGridItemProps} />
    </Grid>
  )
}

export default ResponsiveCarousel

import React from 'react'
import Slider from 'react-slick'
import { BackgroundImage } from '@deal/components'
import coverExpertsInfo from './data'
import styles from './styles.css'

const CoverExpertsInfo: React.FC = () => {
  const settings = {
    fade: true,
    infinite: true,
    speed: 3000,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true
  }
  return (
    <div className={styles.wrapper}>
      <Slider {...settings}>
        {coverExpertsInfo.map((expert, index) => {
          return (
            <BackgroundImage
              key={index}
              src={expert.coverImg}
              size={'100%'}
              className={styles.coverImage}
              lazyLoad={false}
              htmlAttributes={{
                alt: expert.name
              }}
            >
              <div className={styles.expertInfoWrapper}>
                <div className={styles.expertName}>{expert.name}</div>
                <div className={styles.expertInfo}>{expert.header}</div>
              </div>
              <div className={styles.coverGradient} />
            </BackgroundImage>
          )
        })}
      </Slider>
    </div>
  )
}

export default CoverExpertsInfo

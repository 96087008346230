import React from 'react'
import { Button } from '@deal/components'
import styles from './styles.css'

interface Props {
  onPathModalOpen: () => void
}

const NewCuration: React.FC<Props> = ({ onPathModalOpen }) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>Looking to buy something else?</div>
      <div className={styles.info}>
        There are thousands of Real Experts on Curated. Click below to match with one who can help
        you find exactly what you’re looking for.
      </div>
      <Button className={styles.cta} onClick={onPathModalOpen} variant="neutral-dark">
        Talk to a Real Expert
      </Button>
    </div>
  )
}

export default NewCuration

import React from 'react'
import classnames from 'classnames'
import { CarouselRenderPaginationPage } from '@deal/components'
import styles from './styles.css'

interface CarouselPaginationProps {
  pages: CarouselRenderPaginationPage[]
  containerClassName?: string
}

const CarouselPagination: React.FC<React.PropsWithChildren<CarouselPaginationProps>> = ({ pages, containerClassName }) => {
  return (
    <div className={classnames(styles.container, containerClassName)}>
      {pages.map(({ active, onClick }, idx) => (
        <div key={idx} className={styles.tickContainer} onClick={onClick}>
          <div className={classnames(styles.tick, { [styles.currentTick]: active })} />
        </div>
      ))}
    </div>
  )
}

export default CarouselPagination

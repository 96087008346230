import TeleScopeIcon from './telescope-icon.svg'
import StarIcon from './star-icon.svg'
import AwardIcon from './award-icon.svg'
export interface Section {
  label: string
  content: string
  link: string
  Icon: React.FunctionComponent
}

const sections: Section[] = [
  {
    label: 'Top recommended',
    content: 'See the products our Real Experts are recommending the most',
    link: '/most-recommended',
    Icon: AwardIcon
  },
  {
    label: 'Expert journal',
    content: 'Product reviews, buying guides and more from our Real Experts',
    link: '/journal',
    Icon: TeleScopeIcon
  },
  {
    label: 'Customer reviews',
    content: 'Find out why people are totally obsessed with Curated',
    link: '/reviews',
    Icon: StarIcon
  }
]

export default sections

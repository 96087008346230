import React, { useState } from 'react'
import classnames from 'classnames'
import { Grid, GridItem } from '@deal/components'
import PageContent from '#src/app/components/PageContent'
import {
  UserJourneyLeadStageForCurationsHeroFragment,
  UserJourneyStageForCurationsHeroFragment
} from './CurationsHero.generated'
import NewCuration from './NewCuration'
import Curation from './Curation'
import ChevronRightIcon from './chevron-right-icon.svg'
import styles from './styles.css'

interface Props {
  onPathModalOpen: () => void
  userJourneyStages: UserJourneyStageForCurationsHeroFragment[]
}

const CurationsHero: React.FC<Props> = ({ onPathModalOpen, userJourneyStages }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const leadStageUserJourneys = userJourneyStages.filter(
    (stage): stage is UserJourneyLeadStageForCurationsHeroFragment => {
      return stage.__typename === 'UserJourneyLeadStage'
    }
  )

  if (leadStageUserJourneys.length === 0) {
    return null
  }

  return (
    <div className={styles.container}>
      <PageContent>
        <div className={styles.desktopSubHeader}>Expert recommendations</div>
        <div className={styles.header}>Recommended by a Real Expert</div>
        <div className={styles.mobileSubHeader}>Personalized picks from our experts</div>
      </PageContent>

      <Grid rowGaps className={styles.desktopCurationsWrapper}>
        {leadStageUserJourneys.map(stage => {
          return (
            <GridItem col={2} mdCol={6} lgCol={4} key={stage.lead.id}>
              <Curation lead={stage.lead} />
            </GridItem>
          )
        })}

        <GridItem col={2} mdCol={6} lgCol={4}>
          <NewCuration onPathModalOpen={onPathModalOpen} />
        </GridItem>
      </Grid>

      <PageContent>
        <div className={styles.mobileCurationsWrapper}>
          <Curation lead={leadStageUserJourneys[0].lead} />

          {leadStageUserJourneys.length > 1 && (
            <>
              <div
                onClick={() => setIsExpanded(!isExpanded)}
                className={classnames(styles.moreCurationWrapper, {
                  [styles.expandedCurationWrapper]: isExpanded
                })}
              >
                <div className={styles.icon}>
                  <ChevronRightIcon />
                </div>
                <div className={styles.ctaText}>More curations</div>

                <div className={styles.number}>{leadStageUserJourneys.length - 1}</div>
              </div>
              {isExpanded && (
                <div className={styles.otherCurations}>
                  {leadStageUserJourneys.slice(1).map(({ lead }) => {
                    return (
                      <Curation
                        className={styles.mobileCuration}
                        lead={lead}
                        key={`mobile-${lead.id}`}
                      />
                    )
                  })}
                </div>
              )}
            </>
          )}
        </div>
      </PageContent>
    </div>
  )
}

export default CurationsHero

import React from 'react'
import { useHistory } from '@deal/router'
import { Grid , GridItem, Modal } from '@deal/components'
import { AttributeInput } from '#src/generated/types'
import { formatPathUrl, useCreateRequestPath } from '#src/app/services/path'
import CategoryNavigationCard from '#src/app/components/CategoryNavigationCard'
import { CategoryNavigationDecoratedGroupForCategoryPathCtaModalFragment } from './CategoryNavigationDecoratedGroupForCategoryPathCtaModal.generated'
import styles from './styles.css'

interface Props {
  initialAttributes?: AttributeInput[]
  isPathModalOpen: boolean
  onPathModalClose: () => void
  navigationGroups: CategoryNavigationDecoratedGroupForCategoryPathCtaModalFragment[]
}

const CategoryPathCtaModal: React.FC<Props> = ({
  initialAttributes = [],
  isPathModalOpen,
  onPathModalClose,
  navigationGroups
}) => {
  const history = useHistory()

  const [createPath, { loading }] = useCreateRequestPath({
    onPathCreated: ({ pathGraphFlow, pathGraphFlowSlug, nodeSlugOrId }) => {
      if (pathGraphFlow) {
        history.push(
          formatPathUrl({
            pathGraphFlowId: pathGraphFlow.pathGraphFlowId,
            nodeSlugOrId,
            pathGraphFlowSlug
          })
        )
      } else {
        throw new Error('Error creating path for `PathDropdown` component!')
      }
    }
  })
  return (
    <Modal
      isOpen={isPathModalOpen}
      onRequestClose={onPathModalClose}
      mobilePosition="bottom"
      contentLabel="Select a category you need expert assistance"
    >
      <div className={styles.header}>Select a category you need expert assistance</div>
      <div className={styles.categoriesWrapper}>
        {navigationGroups.map(({ label, navigationRevisions }, index) => {
          const navigationRevisionsWithCategory = navigationRevisions.filter(
            navRevision => navRevision.category
          )

          if (!navigationRevisionsWithCategory.length) {
            return null
          }

          return (
            <div className={styles.categoryWrapper} key={`${label}-${index}`}>
              <div className={styles.title}>{label}</div>
              <div className={styles.categoryContent}>
                <Grid rowGaps className={styles.gridContent}>
                  {navigationRevisionsWithCategory.map(categoryNavigationRevision => {
                    const category = categoryNavigationRevision.category

                    if (!category) {
                      return null
                    }

                    return (
                      <GridItem key={categoryNavigationRevision.id} smCol={4} mdCol={4}>
                        <CategoryNavigationCard
                          categoryNavigationRevision={categoryNavigationRevision}
                          layout="small"
                          onClick={() => {
                            if (loading) {
                              return
                            }

                            createPath({
                              pathSelector: {
                                categorySlugHierarchyLookup: category.slug
                              },
                              initialAttributes,
                              trackingCodes: {
                                ctaName: 'categories-page-hero-cta',
                                sourceKey: 'categories-page-shopping-with-experts-cta'
                              }
                            })
                          }}
                        />
                      </GridItem>
                    )
                  })}
                </Grid>
              </div>
            </div>
          )
        })}
      </div>
    </Modal>
  )
}

export default CategoryPathCtaModal

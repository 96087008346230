import React from 'react'
import { Area, Grid, Heading } from '@deal/bluxome'
import PageContent from '#src/app/components/PageContent'
import ExploreCategoriesNavigation, {
  ExploreCategoriesNavigationProps
} from '#src/app/components/ExploreCategoriesNavigation'
import styles from './styles.css'

interface ExploreCategoriesProps extends ExploreCategoriesNavigationProps {}

const ExploreCategories: React.FC<ExploreCategoriesProps> = props => {
  if (props.layout === 'scout') {
    return (
      <div className={styles.scoutContainer}>
        <Grid rowGap={32} rowGapMd={56}>
          <Area column="span 6" className="text-center">
            <Heading as="h2" size="h2">
              Find exactly what you're looking for
            </Heading>
          </Area>
          <Area column="span 6" bleed="full">
            <ExploreCategoriesNavigation {...props} />
          </Area>
        </Grid>
      </div>
    )
  }

  return (
    <PageContent className={styles.container}>
      <h1 className={styles.header}>Shop categories</h1>
      <div className={styles.subHeader}>
        We don't sell junk. Everything on Curated has been chosen, and tested, by a Real Expert.
      </div>
      <ExploreCategoriesNavigation {...props} />
    </PageContent>
  )
}

export default ExploreCategories

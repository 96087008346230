import React from 'react'
import classnames from 'classnames'
import { Link } from '@deal/router'
import { Button, Carousel } from '@deal/components'
import ExpertProfileLink from '#src/app/components/ExpertProfileLink'
import ExpertAvatarLegacy from '#src/app/components/ExpertAvatarLegacy'
import CurationItem from '#src/app/components/CurationItem'
import { UserJourneyLeadForCurationFragment } from './Curation.generated'
import ChevronRightIcon from '../chevron-right-icon.svg'
import styles from './styles.css'

interface Props {
  className?: string
  lead: UserJourneyLeadForCurationFragment
}

const Curation: React.FC<Props> = ({ className, lead }) => {
  const userNeeds = lead.userNeeds
  const { expert } = lead

  return (
    <div className={classnames(styles.container, className)}>
      <Link to={`/curations/products/${lead.id}`} className={styles.header}>
        {lead.category.pluralDisplayName}
        <div className={styles.iconWrapper}>
          <ChevronRightIcon />
        </div>
      </Link>

      <div className={styles.expertInfoWrapper}>
        <div className={styles.avatarWrapper}>
          <ExpertProfileLink expert={expert}>
            <ExpertAvatarLegacy expert={expert} size={28} />
          </ExpertProfileLink>
        </div>
        <div>
          <ExpertProfileLink
            expert={expert}
            className={styles.name}
          >{`${expert.shortName}`}</ExpertProfileLink>
          's recommendations
        </div>
      </div>

      <div className={styles.curationList}>
        <Carousel
          edgePadding={0}
          slidesToShow={1}
          variableWidth={true}
          gutterSize={8}
          xsProps={{
            arrows: false
          }}
          smProps={{
            arrows: false
          }}
        >
          {userNeeds.map(userNeed => {
            if (userNeed.curatedItems.count === 0) {
              return null
            }

            return (
              <CurationItem
                key={userNeed.id}
                title={userNeed.curatedItems.items[0].category.singularDisplayName}
                subTitle={`${userNeed.curatedItems.count} ${
                  userNeed.curatedItems.count > 1 ? 'options' : 'option'
                }`}
                itemImg={userNeed.curatedItems.items[0]?.sellable.images[0]?.url}
                linkTo={`/curations/products/${lead.id}#${userNeed.category.slug}`}
              />
            )
          })}
        </Carousel>
      </div>
      <Link to={`/curations/products/${lead.id}`}>
        <Button className={styles.cta} variant="secondary" size="small">
          View Curation
        </Button>
      </Link>
    </div>
  )
}

export default Curation

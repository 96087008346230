import React from 'react'
import HeroSkeleton from './components/HeroSkeleton'
import ExploreCategoriesSkeleton from './components/ExploreCategorySkeleton'

const HomeRouteLoader: React.FC = () => {
  return (
    <>
      <HeroSkeleton />
      <ExploreCategoriesSkeleton />
    </>
  )
}

export default HomeRouteLoader

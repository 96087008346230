import React from 'react'
import { Link } from '@deal/router'
import PageContent from '#src/app/components/PageContent'
import sections from './data'
import ChevronRightIcon from './chevron-right-icon.svg'
import styles from './styles.css'

const ExploreContent: React.FC = () => {
  return (
    <PageContent className={styles.container}>
      <div className={styles.header}>Explore expert content</div>
      <div className={styles.sectionsContainer}>
        {sections.map(({ label, content, link, Icon }, index) => {
          return (
            <Link to={link} key={`${label}-${index}`}>
              <div className={styles.section} key={`${label}-${index}`}>
                <div className={styles.sectionIcon}>
                  <Icon />
                </div>
                <div className={styles.sectionContentWrapper}>
                  <div>
                    <div className={styles.sectionHeader}>{label}</div>
                    <div className={styles.sectionContent}>{content}</div>
                    <div className={styles.sectionCtaLink}>
                      <div className={styles.sectionCtaText}>Show more</div>
                      <div className={styles.sectionCtaIcon}>
                        <ChevronRightIcon />
                      </div>
                    </div>
                  </div>

                  <div className={styles.phoneScreenIcon}>
                    <ChevronRightIcon />
                  </div>
                </div>
              </div>
            </Link>
          )
        })}
      </div>
    </PageContent>
  )
}

export default ExploreContent

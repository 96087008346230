import React from 'react'
import { LocationDescriptor } from 'history'
import classnames from 'classnames'
import { Image } from '@deal/components'
import LinkWithOffset from '../LinkWithOffset'
import styles from './styles.css'

interface Props {
  title: string
  subTitle: string
  itemImg?: string
  linkTo: LocationDescriptor
  isLargeLayout?: boolean
}

const CurationItem: React.FC<Props> = ({ title, subTitle, itemImg, linkTo, isLargeLayout }) => {
  return (
    <LinkWithOffset
      to={linkTo}
      className={classnames(styles.container, {
        [styles.largeLayoutContainer]: isLargeLayout
      })}
    >
      {itemImg && (
        <div className={styles.imageContainer}>
          <Image className={styles.image} src={itemImg} size="60px" />
        </div>
      )}
      <div className={styles.needTitle}>{title}</div>

      <span className={styles.needSubTitle}>{subTitle}</span>
    </LinkWithOffset>
  )
}

export default CurationItem

import React from 'react'
import { Organization, WithContext } from 'schema-dts'
import { sanitizeAndStringify } from '#src/app/services/xss'
import config from '#src/app/config'
import Logo from './logo.jpg'

const SchemaData: React.FC<React.PropsWithChildren<unknown>> = () => {
  // See: http://schema.org/Organization
  const schemaMarkup: WithContext<Organization> = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Curated.com',
    legalName: 'Curated.com',
    url: `${config.get('consumer.protocol')}://${config.get('consumer.host')}`,
    logo: Logo,
    foundingDate: '2017',
    address: {
      '@type': 'PostalAddress',
      streetAddress: '638 4th St',
      addressLocality: 'San Francisco',
      addressRegion: 'CA',
      postalCode: '94107',
      addressCountry: 'USA'
    },
    numberOfEmployees: {
      '@type': 'QuantitativeValue',
      value: '50'
    },
    contactPoint: {
      '@type': 'ContactPoint',
      contactType: 'Cusomer Support',
      telephone: '+1-415-855-1825',
      email: 'support@curated.com'
    },
    sameAs: [
      config.get('instagramLink'),
      config.get('youtubeLink'),
      config.get('facebookLink'),
      config.get('twitterLink'),
      config.get('linkedInLink')
    ]
  }

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: sanitizeAndStringify(schemaMarkup) }}
    />
  )
}

export default SchemaData

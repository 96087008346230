import React from 'react'
import { Area, Button, Grid, Heading } from '@deal/bluxome'
import { useBreakpoint } from '#src/app/hooks/useBreakpoint'
import { ArticleCarousel } from '#src/app/components/ArticleCarousel'
import { useHomepagePopularArticlesQuery } from './HomepagePopularArticles.generated'
import styles from './styles.css'

export const PopularArticles: React.FC = () => {
  const isLargerThanMd = useBreakpoint('md', 'bluxome')
  const { data } = useHomepagePopularArticlesQuery()
  const { sections } = data?.articleTopLevelNavigation || {}
  const { articles: popularArticles = [] } =
    sections?.find(s => s.displayName.toLowerCase() === 'popular') || {}

  return (
    <div className={styles.popularArticles}>
      <Grid>
        <Area column="span 6" bleed="right" bleedBelow="md">
          <div className={styles.carouselHeading}>
            <Heading as="h3" size="h3">
              Popular articles
            </Heading>
            <Button
              link={{ to: '/journal' }}
              color="learn-blue"
              style="outline"
              size="small"
              aria-label="view all articles"
            >
              View all
            </Button>
          </div>
          <ArticleCarousel
            title={null}
            articles={popularArticles}
            trackingRef="homepage-popular-articles"
            slides={1}
            slidesMd={4}
            slidesLg={4}
            spacing="tight"
            containerBgColor="color"
            paginationButtonPlacement={isLargerThanMd ? 'bottom' : undefined}
            className={styles.carousel}
          />
        </Area>
      </Grid>
    </div>
  )
}

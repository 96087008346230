import TaylorMadeImg from './taylormade.png'
import SimmsImg from './simms.png'
import SalomonImg from './salomon.png'
import RossignolImg from './rossignol.png'
import RocketEspressoImg from './rocketespresso.png'
import OwletImg from './owlet.png'
import OrvisImg from './orvis.png'
import MountainHardWearImg from './mountainhardwear.png'
import MolekuleImg from './molekule.png'
import MarmotImg from './marmot.png'
import ErgobabyImg from './ergobaby.png'
import CotopaxiImg from './cotopaxi.png'
import ColemanImg from './coleman.png'
import CobraImg from './cobra.png'
import CallawayImg from './callaway.png'
import BurtonImg from './burton.png'
import BabybjornImg from './babybjorn.png'
export interface Brand {
  label: string
  img: string
}

const brands: Brand[] = [
  { label: 'Rossignol', img: RossignolImg },
  { label: 'Salomon', img: SalomonImg },
  { label: 'Babybjorn', img: BabybjornImg },
  { label: 'TaylorMade', img: TaylorMadeImg },
  { label: 'MountainHardWear', img: MountainHardWearImg },
  { label: 'Callaway', img: CallawayImg },
  { label: 'Burton', img: BurtonImg },
  { label: 'Simms', img: SimmsImg },
  { label: 'Molekule', img: MolekuleImg },
  { label: 'Coleman', img: ColemanImg },
  { label: 'Marmot', img: MarmotImg },
  { label: 'Orvis', img: OrvisImg },
  { label: 'Cotopaxi', img: CotopaxiImg },
  { label: 'Cobra', img: CobraImg },
  { label: 'Owlet', img: OwletImg },
  { label: 'RocketEspresso', img: RocketEspressoImg },
  { label: 'Ergobaby', img: ErgobabyImg }
]

export default brands

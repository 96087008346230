import SaraImg from './sara.png'
import NicoleImg from './nicole.png'
import JeffImg from './jeff.png'
import JacobImg from './jacob.png'
export interface ExpertInfo {
  name: string
  header: string
  coverImg: string
}

const coverExpertsInfo: ExpertInfo[] = [
  {
    name: 'Nicole, Real Expert',
    header: 'Golf champ from Tampa, FL',
    coverImg: NicoleImg
  },
  {
    name: 'Jacob, Real Expert',
    header: 'Angler from Hudson, OH',
    coverImg: JacobImg
  },
  {
    name: 'Jeff, Real Expert',
    header: 'Coffee roaster from Telluride, CO ',
    coverImg: JeffImg
  },

  {
    name: 'Sara, Real Expert',
    header: 'US ski coach from Longmont, CO ',
    coverImg: SaraImg
  }
]

export default coverExpertsInfo

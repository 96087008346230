import React from 'react'
import classnames from 'classnames'
import { Image } from '@deal/components'
import { getActiveExpertCountForCategory } from '#src/app/services/categories'
import { useBreakpoint } from '#src/app/hooks/useBreakpoint'
import { FormattedCategoryNavigationRevisionForCategoryNavigationCardFragment } from './FormattedCategoryNavigationRevisionForCategoryNavigationCard.generated'
import {
  ScoutCategoryNavigationCard,
  ScoutCategoryNavigationCardProps
} from './ScoutCategoryNavigationCard'
import styles from './styles.css'

interface CategoryNavigationCardProps extends ScoutCategoryNavigationCardProps {
  categoryNavigationRevision: FormattedCategoryNavigationRevisionForCategoryNavigationCardFragment
  layout?: 'standard' | 'small' | 'scout'
  onClick?: () => void
}

const CategoryNavigationCard: React.FC<CategoryNavigationCardProps> = ({
  categoryNavigationRevision,
  onClick,
  layout,
  aspectRatioLg
}) => {
  const isLargerThanSm = useBreakpoint('sm')

  if (layout === 'scout') {
    return (
      <ScoutCategoryNavigationCard
        categoryNavigationRevision={categoryNavigationRevision}
        aspectRatioLg={aspectRatioLg}
      />
    )
  }

  const category = categoryNavigationRevision.category
  const categoryAssetRevision = categoryNavigationRevision.category?.categoryAssetRevision
  const expertCount = category ? getActiveExpertCountForCategory(category) : 0

  return (
    <div
      onClick={onClick}
      className={classnames(styles.cardWrapper, {
        [styles.smallLayout]: layout === 'small' || !isLargerThanSm
      })}
    >
      <div className={styles.categoryCard}>
        <div className={styles.standardImageContainer}>
          {categoryAssetRevision?.croppedInventoryImage && (
            <Image
              htmlAttributes={{
                alt: categoryNavigationRevision.displayLabel
              }}
              className={styles.standardImage}
              src={categoryAssetRevision?.croppedInventoryImage.image.url}
              size="25vw"
              sizeMd="16vw"
            />
          )}
        </div>
        <div className={styles.categoryDetails}>
          <div>
            <div className={styles.categoryTitle}>{categoryNavigationRevision.displayLabel}</div>
            {expertCount !== undefined && expertCount > 0 && (
              <div className={styles.categoryExpertCount}>
                {/* Add +/- ten percent to the expert count to add some variance to the counts for categories within the same department */}
                {expertCount} expert{expertCount > 1 ? 's' : ''}
              </div>
            )}
          </div>
        </div>

        <div className={styles.smallImageContainer}>
          {categoryAssetRevision?.croppedInventoryImage && (
            <Image
              className={styles.smallImage}
              htmlAttributes={{
                alt: categoryNavigationRevision.displayLabel
              }}
              src={categoryAssetRevision?.croppedInventoryImage.image.url}
              size="25vw"
              sizeMd="16vw"
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default CategoryNavigationCard

import React, { useCallback, useState } from 'react'
import classnames from 'classnames'
import { Link } from '@deal/router'
import { GridItem, Grid as LegacyGrid } from '@deal/components'
import { Area, Grid, Heading } from '@deal/bluxome'
import { buildCategoryOrProductListingPageUrl } from '#src/app/services/search/buildCategoryOrProductListingPageUrl'
import { CategoryNavigationDecoratedGroupForExploreCategoriesNavigationFragment } from '#src/app/fragments/ExploreCategoriesNavigation.generated'
import CategoryNavigationCard from '#src/app/components/CategoryNavigationCard'
import styles from './styles.css'

export interface ExploreCategoriesNavigationProps {
  navigationGroups: CategoryNavigationDecoratedGroupForExploreCategoriesNavigationFragment[]
  layout?: 'standard' | 'small' | 'scout'
  cardGridContentClassName?: string
  columns?: 'two' | 'responsive'
}

const ExploreCategoriesNavigation: React.FC<ExploreCategoriesNavigationProps> = ({
  navigationGroups,
  layout,
  cardGridContentClassName,
  columns = 'responsive'
}) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const groupsWithCategories = navigationGroups.filter(group =>
    group.navigationRevisions.some(revision => !!revision.category)
  )

  const GridComponent: React.FC<React.PropsWithChildren<{ className?: string }>> = useCallback(
    ({ className, children }) => {
      return layout === 'scout' ? (
        <Grid rowGap={16}>{children}</Grid>
      ) : (
        <LegacyGrid rowGaps className={classnames(styles.gridContent, className)}>
          {children}
        </LegacyGrid>
      )
    },
    [layout]
  )

  return (
    <div>
      <div
        className={classnames({
          [styles.tabNavList]: layout !== 'scout',
          [styles.scoutTabNavList]: layout === 'scout'
        })}
        role="tablist"
      >
        {groupsWithCategories.map(({ label }, index) => {
          const isActive = activeIndex === index
          return (
            <div
              className={classnames({
                [styles.tabTitle]: layout !== 'scout',
                [styles.tabTitleActive]: layout !== 'scout' && isActive,
                [styles.scoutTabTitle]: layout === 'scout',
                [styles.scoutTabTitleActive]: layout === 'scout' && isActive
              })}
              role="tab"
              id={`tab-${index}`}
              aria-selected={isActive}
              aria-controls={`tab-panel-${index}`}
              tabIndex={isActive ? 0 : -1}
              key={`${label}-${index}`}
              onClick={() => {
                setActiveIndex(index)
              }}
            >
              {layout === 'scout' ? (
                <Heading as="span" size="h4" color={isActive ? 'neutrals-white' : 'neutrals-black'}>
                  {getTransformedLabel(label)}
                </Heading>
              ) : (
                <>{label}</>
              )}
            </div>
          )
        })}
      </div>
      <div
        className={classnames({
          [styles.scoutPanels]: layout === 'scout'
        })}
      >
        {navigationGroups.map(({ label, navigationRevisions }, index) => {
          const navigationRevisionsWithCategory = navigationRevisions.filter(
            revision => revision.category
          )

          return (
            <div
              id={`tab-panel-${index}`}
              key={`${label}-panel-${index}`}
              className={classnames({
                [styles.tabPanel]: layout !== 'scout',
                [styles.tabPanelActive]: layout !== 'scout' && activeIndex === index,
                [styles.hideBelowMd]: layout === 'scout' && activeIndex !== index
              })}
              tabIndex={index}
              aria-labelledby={`tab-${index}`}
              aria-hidden={activeIndex !== index}
              role="tabpanel"
            >
              <GridComponent className={cardGridContentClassName}>
                {layout === 'scout' && (
                  <Area column="span 6" className={styles.hideBelowMd}>
                    <Heading as="h4" size="h4">
                      {getTransformedLabel(label)}
                    </Heading>
                  </Area>
                )}
                {navigationRevisionsWithCategory.map(categoryNavigationRevision => {
                  const linkTo =
                    categoryNavigationRevision.displayLabelLink?.relativeConsumerWebPath ||
                    (categoryNavigationRevision.category &&
                      buildCategoryOrProductListingPageUrl(categoryNavigationRevision.category))

                  if (!linkTo) {
                    return null
                  }

                  if (layout === 'scout') {
                    // Find the largest number of columns (from 4,3,2,1) that evenly divides the total items
                    // This ensures items can be arranged in equal columns at lg breakpoint (1040px)
                    const totalItems = navigationRevisionsWithCategory.length
                    const numColumnsLg = [4, 3, 2, 1].find(n => totalItems % n === 0)!
                    return (
                      <Area
                        key={categoryNavigationRevision.id}
                        column="span 6"
                        columnMd="span 3"
                        columnLg={`span ${12 / numColumnsLg}`}
                      >
                        <Link
                          to={linkTo}
                          aria-label={categoryNavigationRevision.displayLabel}
                          className="contents"
                        >
                          <CategoryNavigationCard
                            layout="scout"
                            categoryNavigationRevision={categoryNavigationRevision}
                            aspectRatioLg={numColumnsLg === 4 ? '17/20' : '3/2'}
                          />
                        </Link>
                      </Area>
                    )
                  }

                  return (
                    <GridItem
                      key={categoryNavigationRevision.id}
                      smCol={columns === 'two' ? 4 : 2}
                      mdCol={columns === 'two' ? 6 : 2}
                    >
                      <Link to={linkTo} aria-label={categoryNavigationRevision.displayLabel}>
                        <CategoryNavigationCard
                          categoryNavigationRevision={categoryNavigationRevision}
                          layout={layout}
                        />
                      </Link>
                    </GridItem>
                  )
                })}
              </GridComponent>
            </div>
          )
        })}
      </div>
    </div>
  )
}

function getTransformedLabel(label: string) {
  return { Outdoors: 'Outdoor Gear', Home: 'Home Goods' }[label] || label
}

export default ExploreCategoriesNavigation

import React, { useRef } from 'react'
import { Link } from '@deal/router'
import { useElementInteractionTracking } from '@deal/dom-hooks'
import { ArticleCard as ArticleCardFromBluxome } from '@deal/bluxome'
import { getExpertExpertise } from '#src/app/services/expert'
import useGetArticleType from '#src/app/services/articles/hooks/useGetArticleType'
import { useUserAgentContext } from '#src/app/containers/UserAgent'
import PlaceholderImage from '#src/app/assets/images/placeholder.png'
import {
  ArticleAuthorForArticleCardFragment,
  ArticleRevisionForArticleCardFragment
} from './ArticleCard.generated'
import { VersusArticleCard } from './VersusArticleCard'
import styles from './styles.css'

interface AuthorInfo {
  name: string
  expertise: string
  image?: string
}

function getAuthorInfo(author?: ArticleAuthorForArticleCardFragment): AuthorInfo {
  if (!author) {
    return {
      name: 'Curated',
      expertise: ''
    }
  }

  switch (author.__typename) {
    case 'BusinessUser':
      return {
        name: author.displayName,
        image:
          author.expertProfile?.profilePhoto?.url || author.articleAuthorProfile?.profilePhoto?.url,
        expertise: getExpertExpertise(author)
      }
    default:
      return {
        name: 'Curated',
        expertise: ''
      }
  }
}

export interface ArticleCardProps {
  article: ArticleRevisionForArticleCardFragment
  containerBgColor: 'white' | 'color'
  trackingRef: string
  onCardClicked?: (selectedArticleId: string) => void
  size: string
  sizeMd?: string
  sizeLg?: string
  sizeXlg?: string
  trackingSourceId?: string
}

export const ArticleCard: React.FC<ArticleCardProps> = ({
  article,
  containerBgColor,
  trackingRef,
  onCardClicked,
  size,
  sizeMd,
  sizeLg,
  sizeXlg,
  trackingSourceId,
  ...linkProps
}) => {
  const { isBot = true } = useUserAgentContext()

  const linkRef = useRef<HTMLAnchorElement>(null)
  const { trackElementClickedEvent } = useElementInteractionTracking({
    ref: linkRef,
    name: 'journal-related-article-carousel-card',
    metadata: { sourceId: trackingSourceId, articleId: article.articleId },
    trackViews: !!trackingSourceId
  })

  const isVersus = useGetArticleType(article) === 'VERSUS'

  const firstAuthor = article.authors.at(0)

  const authorInfo = getAuthorInfo(firstAuthor)

  return (
    <Link
      ref={linkRef}
      to={`/journal/${article.friendlyId}/${article.slug}`}
      trackingRef={isBot ? undefined : trackingRef}
      onClick={() => {
        !!trackingSourceId && trackElementClickedEvent()
        onCardClicked?.(article.id)
      }}
      className={styles.link}
      {...linkProps}
    >
      {isVersus && article.versusMedia ? (
        <VersusArticleCard
          article={article}
          author={authorInfo}
          containerBgColor={containerBgColor}
          size={size}
          sizeMd={sizeMd}
          sizeLg={sizeLg}
          sizeXlg={sizeLg}
        />
      ) : (
        <ArticleCardFromBluxome
          image={{
            src: article.featuredImage?.media.url || PlaceholderImage,
            alt: article.featuredImage?.alternativeText || 'Placeholder image',
            size,
            sizeMd,
            sizeLg,
            sizeXlg
          }}
          containerBgColor={containerBgColor}
          title={article.title}
          summary={article.summaryText || undefined}
          author={{
            name: authorInfo.name,
            image: authorInfo.image,
            expertise: authorInfo.expertise
          }}
        />
      )}
    </Link>
  )
}

import React from 'react'
import { Heading, InlineBanner, Text } from '@deal/bluxome'
import { ScoutStartChattingButton } from '#src/app/routes/scout/components/ScoutStartChattingButton'
import SkiJumpImage from './scout-banner-ski-jump.png'

export const ScoutBanner: React.FC = () => {
  return (
    <InlineBanner
      style="expert"
      title={
        <Heading as="span" color="neutrals-black">
          <>Still stuck?</>
          <br />
          <>Scout can help.</>
        </Heading>
      }
      subtitle={
        <Text as="span" color="neutrals-black">
          With the knowledge of thousands of experts, Scout can give you tailored recommendations,
          all for free.
        </Text>
      }
      imageSrc={SkiJumpImage}
      button={fullWidth => (
        <ScoutStartChattingButton label="Get started for free" icon={null} fullWidth={fullWidth} />
      )}
    />
  )
}

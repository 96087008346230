import React from 'react'
import { useLocation } from '@deal/router'
import { Button } from '@deal/components'
import PageContent from '#src/app/components/PageContent'
import LinkWithOffset from '#src/app/components/LinkWithOffset'
import CoverExpertsInfo from './CoverExpertsInfo'
import styles from './styles.css'

interface Props {
  onPathModalOpen: () => void
}

const Hero: React.FC<Props> = ({ onPathModalOpen }) => {
  const location = useLocation()

  return (
    <div className={styles.container}>
      <PageContent className={styles.heroWrapper} gridWrapperClassName={styles.gridWrapperClass}>
        <div className={styles.contentWrapper}>
          <div className={styles.header}>Find exactly what you’re looking for</div>
          <div className={styles.subHeader}>Get personalized product picks from a Real Expert.</div>
          <div className={styles.ctaWrapper}>
            <Button
              variant="primary-gradient"
              className={styles.ctaBtn}
              size="large"
              onClick={onPathModalOpen}
            >
              Shop with a Real Expert
            </Button>
            <LinkWithOffset
              to={`${location.pathname}${location.search}#expert`}
              aria-label="Shop with an expert"
            >
              <Button variant="primary-link" className={styles.desktopSizeShopWithExpertCta}>
                What’s a Real Expert?
              </Button>
            </LinkWithOffset>
          </div>
          <div className={styles.info}>Totally unbiased. Zero pressure. Trusted brands.</div>{' '}
          <LinkWithOffset
            to={`${location.pathname}${location.search}#expert`}
            aria-label="Shop with an expert"
          >
            <Button variant="primary-link" className={styles.mobileSizeShopWithExpertCta}>
              What’s a Real Expert?
            </Button>
          </LinkWithOffset>
        </div>
      </PageContent>
      <div className={styles.expertWrapper}>
        <CoverExpertsInfo />
      </div>
    </div>
  )
}

export default Hero

import * as History from 'history'
import { buildCategoryOrProductListingPageUrl } from '../services/search/buildCategoryOrProductListingPageUrl'
import { useDepartment } from '../context/department'

export default () => {
  const department = useDepartment()
  return department
    ? buildCategoryOrProductListingPageUrl(department.topLevelCategory)
    : History.createLocation({
        pathname: '/'
      })
}

import React from 'react'
import { Link, LinkProps } from '@deal/router'
import { HEADER_HEIGHT } from '#src/app/hooks/useScrollToWithHeaderOffset'

const LinkWithOffset: React.FC<
  React.PropsWithChildren<Omit<LinkProps, 'scrollOffset'>>
> = props => {
  return <Link scrollOffset={HEADER_HEIGHT} {...props} />
}

export default LinkWithOffset

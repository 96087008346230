import React, { useRef } from 'react'
import { useOnScreenCallbackOnce } from '@deal/dom-hooks'
import { Carousel, CarouselProps, CarouselSlide, Rounded } from '@deal/bluxome'
import { ArticleRevisionForArticleCardFragment } from '#src/app/components/ArticleCard/ArticleCard.generated'
import { ArticleCard } from '#src/app/components/ArticleCard'
import { useArticleRevisionsForArticleCarouselLazyQuery } from './ArticleRevisionsForArticleCarousel.generated'
import { Skeleton } from '../SkeletonLoader'
import styles from './styles.css'

interface ArticleCarouselProps
  extends Partial<
    Pick<
      CarouselProps,
      'title' | 'spacing' | 'paginationButtonPlacement' | 'slides' | 'slidesMd' | 'slidesLg'
    >
  > {
  articles: ArticleRevisionForArticleCardFragment[]
  lazyLoadArticleIds?: string[]
  onCardClicked?: (selectedArticleId: string) => void
  trackingRef: string
  containerBgColor: 'white' | 'color'
  trackingSourceId?: string
  className?: string
}

export const ArticleCarousel: React.FC<ArticleCarouselProps> = ({
  articles,
  lazyLoadArticleIds,
  onCardClicked,
  trackingRef,
  containerBgColor,
  title = 'Expert articles',
  trackingSourceId,
  paginationButtonPlacement,
  spacing,
  className,
  slides = 1,
  slidesMd,
  slidesLg = 4
}) => {
  const listRef = useRef<HTMLDivElement>(null)

  const [lazyLoadArticles, { data: lazyLoadedData }] =
    useArticleRevisionsForArticleCarouselLazyQuery({
      variables: {
        articleRevisionIds: lazyLoadArticleIds
      }
    })

  useOnScreenCallbackOnce({
    ref: listRef,
    options: {
      threshold: 0
    },
    enabled: !!lazyLoadArticleIds && lazyLoadArticleIds.length > 0,
    callback: () => {
      lazyLoadArticles()
    }
  })

  return (
    <div className={className} ref={listRef}>
      <Carousel
        title={title}
        paginated={false}
        slides={slides}
        slidesMd={slidesMd}
        slidesLg={slidesLg}
        spacing={spacing}
        paginationButtonPlacement={paginationButtonPlacement}
      >
        {[...articles, ...(lazyLoadedData?.articleRevisions || [])].map(article => {
          return (
            <CarouselSlide key={article.id}>
              <ArticleCard
                key={article.id}
                article={article}
                trackingRef={trackingRef}
                containerBgColor={containerBgColor}
                onCardClicked={onCardClicked}
                size="100vw"
                sizeLg="25vw"
                trackingSourceId={trackingSourceId}
              />
            </CarouselSlide>
          )
        })}
        {!lazyLoadedData &&
          lazyLoadArticleIds?.map(articleId => {
            return (
              <CarouselSlide key={articleId}>
                <Rounded clipContent>
                  <Skeleton className={styles.cardSkeleton} />
                </Rounded>
              </CarouselSlide>
            )
          })}
      </Carousel>
    </div>
  )
}

import RealPassionImg from './real-passion.png'
import RealExperienceImg from './real-experience.png'
import RealAdviceImg from './real-advice.png'
export interface Section {
  label: string
  content: string
  img: string
}

const sections: Section[] = [
  {
    label: 'Real experience',
    content:
      'Anyone can tell you what a product does, Real Experts tell you how it feels. Ask someone who has been there, done that. ',
    img: RealExperienceImg
  },
  {
    label: 'Real passion',
    content:
      'Real Experts aren’t sales assistants. They love what they do and want you to love it too. ',
    img: RealPassionImg
  },
  {
    label: 'Real advice',
    content:
      'No sneaky sales tactics or commission-driven upselling. Just always unbiased, totally personalized advice.',
    img: RealAdviceImg
  }
]

export default sections

import React from 'react'
import { usePageKey } from '@deal/router'
import { useAsyncScript } from '@deal/dom-hooks'
import { useIdentityContext } from '#src/app/containers/Identity'
import config from '#src/app/config'

interface HotjarSnippetProps {
  treatment?: string | null
}

const HotjarSnippet: React.FC<React.PropsWithChildren<HotjarSnippetProps>> = ({ treatment }) => {
  const { sessionId, myself } = useIdentityContext()
  const pageKey = usePageKey()
  const siteId = config.get('hotjar.siteId')
  const sdkVersion = config.get('hotjar.sdkVersion')

  useAsyncScript({
    scriptSrc: config
      .get('hotjar.scriptSrc')
      .replace('SITE_ID', siteId)
      .replace('SDK_VERSION', sdkVersion),
    onBeforeAppend: () => {
      /**
       * Bootstrap the hotjar object, adapted from their tracking code documentation:
       * https://help.hotjar.com/hc/en-us/articles/115009336727-How-to-Install-your-Hotjar-Tracking-Code
       */
      window.hj =
        window.hj ||
        function () {
          // eslint-disable-next-line prefer-rest-params -- copied from a hotjar snippet
          ;(window.hj.q = window.hj.q || []).push(arguments)
        }

      window._hjSettings = { hjid: siteId, hjsv: sdkVersion }
    },
    onScriptLoaded: () => {
      /**
       * To correctly attribute treatments to their respective routes, we only invoke the 'treatment' tag when the script is
       * loaded on a page that has a matching landing page key. If always invoked, it could attribute treatments to routes
       * whose page keys have changed and the script is loaded late. This means dropping some user's heatmaps on the floor
       * but it means true reads within hotjar. We always want to identify a user by their session when possible.
       *
       * Since landing page and hiring page keys are dynamically generated, we should partial match.
       */
      const hotjarTreatmentMatches = ['landing', 'lp', 'apply']
      const isMatchingHeatmapPage = hotjarTreatmentMatches.find(entry => !!pageKey?.includes(entry))

      window.hj &&
        window.hj('identify', sessionId, { treatment: isMatchingHeatmapPage ? treatment : null })

      /**
       * If on a PDP or SRP, trigger a recording session via the events API
       */
      if (
        !!myself &&
        ['product-details', 'product-listing', 'search', 'category'].includes(pageKey ?? '')
      ) {
        window.hj && window.hj('event', 'trigger_recording_session')
      }
    }
  })

  return null
}

export default HotjarSnippet

import React from 'react'
import { Link } from '@deal/router'
import { BackgroundImage, CategoryIcon } from '@deal/components'
import { getExpertImageAltText } from '#src/app/services/expert'
import { BusinessUserForExpertCommunityFragment } from '../BusinessUserForExpertCommunity.generated'
import StarIcon from './star-icon.svg'
import LocationIcon from './location-icon.svg'
import styles from './styles.css'

interface Props {
  expert: BusinessUserForExpertCommunityFragment
}

const FeaturedExpertsCard: React.FC<Props> = ({ expert }) => {
  const path = `/experts/${expert.vanityId || expert.id}`
  const expertProfile = expert.expertProfile!
  const expertImageAltText = getExpertImageAltText(expert)
  const expertStarRating = expert.expertStats?.averageRating
  const expertTotalReviews = expert.expertStats?.totalReviews

  const getLocationName = (city?: string | null, state?: string | null) => {
    return `${city || ''}${state ? `, ${state}` : ''}`
  }

  if (!expertProfile || !expertProfile.profilePhoto) {
    return null
  }

  return (
    <Link to={path}>
      <BackgroundImage
        containerClassName={styles.container}
        src={expertProfile.profilePhoto!.url}
        size="75vw"
        sizeSm="33vw"
        sizeMd="25vw"
        imgixParams={{
          fit: 'crop'
        }}
        contentClassName={styles.contentWrapper}
        className={styles.featuredImage}
        htmlAttributes={{
          alt: expertImageAltText
        }}
        id={`expert-card-image-${expert.id}`}
      >
        <div className={styles.categoryBadge}>
          <div className={styles.categoryIcon}>
            <CategoryIcon
              categorySlug={expert.expertAttributes.category.slug}
              color="white"
              width={24}
              height={24}
            />
          </div>
          <span className={styles.categoryName}>
            {expert.expertAttributes.category.pluralDisplayName}
          </span>
        </div>
        <div className={styles.bottom}>
          <div className={styles.expertName}>{expert.displayName}</div>
          <div className={styles.expertBackground}>{expertProfile.background}</div>
          <div className={styles.locationAndReview}>
            {!!expert.location && (
              <div className={styles.expertLocation}>
                <div className={styles.icon}>
                  <LocationIcon />
                </div>
                {getLocationName(expert.location?.city, expert.location?.state)}
              </div>
            )}

            {!!expertStarRating && (
              <div className={styles.reviewContainer}>
                <div className={styles.icon}>
                  <StarIcon />
                </div>

                <div className={styles.reviewCount}>{`${expertStarRating.toFixed(1)} stars  ${
                  expertTotalReviews ? `  (${expertTotalReviews})` : ''
                }`}</div>
              </div>
            )}
          </div>
        </div>
      </BackgroundImage>
    </Link>
  )
}

export default FeaturedExpertsCard

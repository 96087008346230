import React from 'react'
import loadable from '@loadable/component'
import { useExperiment } from '@deal/experiment-js'
import { DepartmentType } from '#src/generated/types'
import useHomepageLocation from '#src/app/hooks/useHomepageLocation'
import { useCategoryOrProductListingLocation } from '#src/app/hooks/useCategoryOrProductListingLocation'
import { useDepartment } from '#src/app/context/department'
import Redirect from '#src/app/components/Redirect'
import Page from '#src/app/components/Page'
import { useHomeRouteQuery } from './HomeRoute.generated'
import { HomePageProps } from './page'
import HomeLoading from './loading'

const HomePage = loadable(() => import(/* webpackChunkName: "home" */ './page'), {
  resolveComponent: components => components.Home
})

const HomeRoute: React.FC = () => {
  const isScoutHomePage =
    useExperiment({
      experiment: 'consumer-scout-home-page',
      defaultTreatment: 'control'
    }).result === 'on'

  const query = useHomeRouteQuery({
    variables: {
      query: {
        departmentIds: [],
        facetedSearch: true,
        facets: [
          {
            name: 'spotlight',
            bucketValues: ['YES']
          }
        ]
      },
      categorySlugs: ['snowboards', 'skis'],
      includeSellables: isScoutHomePage
    }
  })

  const categoryOrProductListingLocation = useCategoryOrProductListingLocation()
  const department = useDepartment()
  const homepageLocation = useHomepageLocation()

  /**
   * The homepage is only accessible directly. If you are already viewing a department or have an existing lead,
   *   we will keep you in that department. Otherwise, we will render the logged-out homepage.
   */
  if (
    homepageLocation.pathname !== '/' &&
    department &&
    department.type !== DepartmentType.CURATED_INTERNAL
  ) {
    return <Redirect to={categoryOrProductListingLocation(department.topLevelCategory)} />
  }

  return (
    <Page
      query={query}
      pageKey="home-page"
      seoTitle="Curated | Shop with an expert, love what you buy"
      seoDescription="Shop with unbiased experts and get personalized advice on products. Big purchases are big decisions – you shouldn’t have to make them alone."
      seoIndexable={true}
      chat={data => {
        return !!data.me?.user.mostRecentLead
      }}
      expert={data => {
        return data.me?.user.mostRecentLead?.expert
      }}
      category={data => {
        return data.me?.user.mostRecentLead?.category
      }}
      department={data => {
        return data.me?.user.mostRecentLead?.department
      }}
      sellable={() => undefined}
      pageComponent={HomePage}
      pageProps={{ isScoutHomePage } as HomePageProps}
      loadingComponent={HomeLoading}
      ogImageUrl={undefined}
    />
  )
}

export { HomeRoute }

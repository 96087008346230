import React, { useCallback } from 'react'
import Marquee from 'react-fast-marquee'
import classNames from 'classnames'
import { Image } from '@deal/components'
import PageContent from '#src/app/components/PageContent'
import brands from './data'
import styles from './styles.css'

interface BrandsProps {
  isInverted?: boolean
}

const Brands: React.FC<BrandsProps> = ({ isInverted }) => {
  const reversedBrands = [...brands].reverse()

  const Wrapper: React.FC<React.PropsWithChildren<{ className: string }>> = useCallback(
    props => (isInverted ? <div {...props} /> : <PageContent {...props} />),
    [isInverted]
  )

  return (
    <Wrapper className={classNames(styles.container, { [styles.inverted]: isInverted })}>
      <div className={styles.header}>Brands we're proud to carry</div>

      <Marquee gradient={!isInverted}>
        {brands.map(({ label, img }) => (
          <div key={label} className={styles.brandContainer}>
            <Image
              lazyLoad={false}
              src={img}
              htmlAttributes={{ alt: label }}
              size="80px"
              className={styles.img}
            />
          </div>
        ))}
      </Marquee>

      <div className={styles.reversedContainer}>
        <Marquee direction="right" gradient={!isInverted}>
          {reversedBrands.map(({ label, img }) => (
            <div key={label} className={styles.brandContainer}>
              <Image
                lazyLoad={false}
                src={img}
                htmlAttributes={{ alt: label }}
                size="80px"
                className={styles.img}
              />
            </div>
          ))}
        </Marquee>
      </div>
    </Wrapper>
  )
}

export default Brands
